import { Box, Button, Card, CardContent, TextField } from '@mui/material';
import {
  FormEvent,
  FunctionComponent,
  ReactElement,
  useContext,
  useState,
} from 'react';
import styled from 'styled-components';
import * as auth from '../auth/auth';
import {
  AuthProviderButtonsMap,
  EAuthProvider,
} from '../components/auth/providerButton';
import { LoadingButton } from '../components/ui/loadingButton';
import { NotificationsContext } from '../contexts/notifications';

const LoginPage = () => {
  const [selectedProvider, setSelectedProvider] = useState(EAuthProvider.EMAIL);

  return (
    <Container>
      <Logo src="/img/logo.svg" />

      <Card>
        <CardContent style={{ paddingTop: 24, width: 390 }}>
          <h2 style={{ margin: '0 0 24px', textAlign: 'center' }}>
            Sign in to Anaxi
          </h2>

          <LoginForm
            selectedProvider={selectedProvider}
            onChangeSelectedProvider={setSelectedProvider}
          />
        </CardContent>
      </Card>
    </Container>
  );
};

const LoginForm: FunctionComponent<{
  selectedProvider?: EAuthProvider;
  onChangeSelectedProvider: (p: EAuthProvider) => void;
}> = ({ selectedProvider, onChangeSelectedProvider }) => {
  const notifications = useContext(NotificationsContext);
  const sso = selectedProvider === EAuthProvider.SSO;

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  function onSubmit(e?: FormEvent, provider = EAuthProvider.EMAIL) {
    e?.preventDefault();

    onChangeSelectedProvider(provider);
    setIsLoading(true);

    switch (provider) {
      case EAuthProvider.GOOGLE:
      case EAuthProvider.MICROSOFT:
      case EAuthProvider.GITHUB:
        return auth.authorizeWithSocial(provider, {});

      case EAuthProvider.SSO:
        return fetch(`/api/sso/${email.split('@')[1]}`, { method: 'POST' })
          .then((res) => res.json())
          .then(({ connection }) => {
            if (!connection) {
              setIsLoading(false);

              return notifications.showNotification({
                type: 'error',
                message: 'SSO is not enabled for this domain',
              });
            }

            return auth.authorizeWithSocial(EAuthProvider.SSO, {
              connection,
            });
          });

      case EAuthProvider.EMAIL:
        return auth
          .authorizeWithCredentials({ email, password })
          .then((res) => {
            setIsLoading(!res.error);
            notifications.showNotification({
              type: 'error',
              message: res.error!,
            });
          });
    }
  }

  function renderAuthButton(provider: EAuthProvider) {
    const Button = AuthProviderButtonsMap[provider];

    return (
      <Button
        prefix=""
        isLoading={false}
        disabled={isLoading}
        onClick={() => onSubmit(undefined, provider)}
      />
    );
  }

  return (
    <>
      {!sso && (
        <Box
          sx={{
            margin: '0 0 30px',
            flexDirection: 'column',
            display: 'flex',
            button: {
              margin: '5px 0',
            },
          }}
        >
          <Button
            variant="outlined"
            size="medium"
            color="info"
            disabled={isLoading}
            onClick={() => {
              setIsLoading(true);
              window.location.href = auth.getAzureADSignInURL();
            }}
          >
            Mapped Employee (SSO)
          </Button>

          <Box
            sx={{
              display: 'flex',
              button: {
                flex: 1,
                marginRight: '10px',
                '&:last-of-type': { marginRight: 0 },
              },
            }}
          >
            {renderAuthButton(EAuthProvider.MICROSOFT)}
            {renderAuthButton(EAuthProvider.GITHUB)}

            <SSOButton
              prefix=""
              disabled={isLoading}
              onClick={() => onChangeSelectedProvider(EAuthProvider.SSO)}
            />
          </Box>

          <h4 style={{ textAlign: 'center', marginBottom: '0', opacity: 0.7 }}>
            ------ <span style={{ margin: '0 10px' }}>OR</span> ------
          </h4>
        </Box>
      )}

      <Box
        sx={{
          form: {
            margin: 0,
            '.MuiInputBase-root': {
              height: '35px',
            },
            '.MuiTextField-root': {
              marginBottom: '11px',
            },
          },
        }}
      >
        <form id="loginForm" onSubmit={(e) => onSubmit(e, selectedProvider)}>
          <TextField
            type="email"
            name="email"
            placeholder="Email"
            size="small"
            variant="outlined"
            fullWidth={true}
            required={true}
            disabled={isLoading}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          {!sso && (
            <>
              <TextField
                type="password"
                name="password"
                placeholder="Password"
                variant="outlined"
                size="small"
                fullWidth={true}
                required={true}
                disabled={isLoading}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

              <LoadingButton
                type="submit"
                variant="outlined"
                disabled={isLoading}
                fullWidth={true}
                size="medium"
              >
                Continue
              </LoadingButton>
            </>
          )}

          {!!sso && (
            <SSOButton
              type="submit"
              disabled={isLoading}
              prefix="Continue with"
              style={{ marginTop: '15px', width: '100%' }}
            />
          )}
        </form>
      </Box>
    </>
  );
};

LoginPage.getLayout = function getLayout(page: ReactElement) {
  return <>{page}</>;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  background: url(/img/login-background.png) no-repeat center right;
  background-size: contain;

  .MuiAlert-root {
    margin-top: 24px;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: rgba(255, 255, 255, 0.4);
  }
`;

const Logo = styled.img`
  width: 200px;
  margin-bottom: 32px;
`;

const SSOButton = AuthProviderButtonsMap[EAuthProvider.SSO];

export default LoginPage;
